import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Banner from "../components/home/banner";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import BannerHeader from "../components/universal/bannerHeader";
import registerBanner from "../assets/register/register-banner.png";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

// Configure FontAwesome
config.autoAddCss = false;

const creditOptions = [
  { sessions: 1, price: 90, total: 90 },
  { sessions: 2, price: 85, total: 170 },
  { sessions: 4, price: 75, total: 300 },
  { sessions: 8, price: 65, total: 520 },
];

// Load Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Glofox Iframe component
const GlofoxIframe = () => {
  useEffect(() => {
    // Load the iframe resizer script
    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js";
    script.onload = () => {
      // Initialize the iframe resizer
      window.iFrameResize(
        {
          log: false,
          checkOrigin: false,
          tolerance: 10,
          sizeHeight: true,
          heightCalculationMethod: "lowestElement",
          minHeight: 300,
          scrolling: "auto",
        },
        "#glofox_2"
      );
    };
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        id="glofox_2"
        src="https://app.glofox.com/portal/#/branch/66fc5e79664ac97e20076622/memberships?header=memberships"
        width="100%"
        height="0"
        scrolling="no"
        frameBorder="0"
        style={{ display: "block" }} // Ensures the iframe displays as a block element
      />
    </div>
  );
};

const Membership = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-black min-h-screen flex flex-col justify-between">
      <div>
        <Banner />
        <Navbar currentMenu={0} />
        <BannerHeader text="REGISTER" background={registerBanner} />
        <br></br>

        <br></br>
        <GlofoxIframe />

        {/* Credit System Section */}
        <div className="text-white text-center px-6">
          <h2 className="text-3xl font-bold mb-6">Monthly not an option?</h2>
          <div className="flex flex-wrap justify-center gap-6">
            {creditOptions.map((option) => (
              <div
                key={option.sessions}
                className="bg-black border border-white rounded-lg shadow-md p-6 w-64 text-center"
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="text-white text-4xl mb-4"
                />
                <h3 className="text-2xl font-bold mb-2">
                  {option.sessions} Session{option.sessions > 1 ? "s" : ""}
                </h3>
                <p className="text-lg">
                  <span className="font-semibold">${option.price}</span> per
                  session
                </p>
                <p className="text-gray-400 mt-2">
                  Total: <span className="font-semibold">${option.total}</span>
                </p>
              </div>
            ))}
          </div>
          <button
            className="mt-8 bg-transparent border border-white text-white px-6 py-2 rounded-lg hover:bg-white hover:text-black transition duration-200"
            onClick={() => (navigate("/contact"))}
          >
            Contact Us for Credit System
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Membership;
