import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div className="text-white p-4 flex items-center text-xs justify-center" style={{ fontFamily: 'Inter, sans-serif', backgroundColor: '#171717'}}>
      <FaInfoCircle className="mr-2" />
      <span> CREDIT SYSTEM ADDED  - <Link to="/membership" className="font-extrabold">REGISTER NOW</Link></span>
    </div>
  );
};

export default Banner;
